import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { getCenter } from 'ol/extent';
import { MapApiKey } from '../Authn/useServcieConfig';
import { MapState } from '../hooks/geomoby/LiveMapRenderer';
import { fitToExtent } from '../hooks/geomoby/MapAnimation';
import { LiveMapShoppingResult } from '../hooks/geomoby/useLiveMapLoader';

export const LAYER_GROUPS = atom<LiveMapShoppingResult | undefined>(undefined);
export const MAP_STATE = atom<MapState | undefined>(undefined);
export const MAP = atom(get => get(MAP_STATE)?.map);
export const ASSETS = atom(get => get(MAP_STATE)?.assets);

export type MapApiKeysRecord = Partial<Record<MapApiKey['provider'], string | undefined>>;
export const MAP_API_KEYS = atom<MapApiKeysRecord>({});
export const SHOW_TOOL_FINDER = atomWithStorage<boolean>('showToolFinder', false);

export const HIGHLIGHT_FENCE = atom(get => {
  return (fenceId: string, filterLayerId?: string): boolean => {
    get(LAYER_GROUPS)?.layers.forEach(({ layer }) => {
      layer.forEach(fence => {
        if (
          fence.data.fenceName === fenceId &&
          (filterLayerId === undefined || filterLayerId === fence.data.layerId)
        ) {
          const geometry = fence.feature.getGeometry();
          fence.feature.set('selected', true);
          const map = get(MAP);
          if (geometry && map) {
            fitToExtent(map.getView(), geometry.getExtent());
            return true;
          }
        }
      });
    });
    return false;
  };
});

export const HIGHLIGHT_MICROFENCE = atom(get => {
  return (microfenceId: Record<string, string | undefined>): boolean => {
    get(LAYER_GROUPS)?.microfences?.forEach(({ data, feature }) => {
      if (JSON.stringify(data.assetId) === JSON.stringify(microfenceId)) {
        const geometry = feature.getGeometry();
        feature.set('selected', true);
        const map = get(MAP);
        if (geometry && map) {
          fitToExtent(map.getView(), geometry.getExtent());
          return true;
        }
      }
    });
    return false;
  };
});

export const HIGHLIGHT_ASSET = atom(get => {
  return (assetId: string) => {
    get(ASSETS)?.forEach((feature, id) => {
      if (id === assetId) {
        const geometry = feature.getGeometry();
        feature.set('selected', true);
        const map = get(MAP);
        if (geometry && map) {
          map.getView().animate({
            center: getCenter(geometry.getExtent()),
            zoom: 19,
            duration: 300,
          });
          return true;
        }
      }
    });
    return false;
  };
});
